// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".link_0iwYj:not(:last-child){margin-right:1rem}.buttonsAndLink_S0B5N{align-items:center}.buttons_9WW8E,.buttonsAndLink_S0B5N,.link_0iwYj{display:inline-flex}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "link_0iwYj",
	"buttonsAndLink": "buttonsAndLink_S0B5N",
	"buttons": "buttons_9WW8E"
};
module.exports = ___CSS_LOADER_EXPORT___;
