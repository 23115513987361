import { render, staticRenderFns } from "./SearchResultTableCellProperty.vue?vue&type=template&id=11c8a783"
import script from "./SearchResultTableCellProperty.vue?vue&type=script&lang=js"
export * from "./SearchResultTableCellProperty.vue?vue&type=script&lang=js"
import style0 from "./SearchResultTableCellProperty.vue?vue&type=style&index=0&id=11c8a783&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,PushButton: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/PushButton/PushButton.vue').default,CompositionSpacer: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,PropertyLink: require('/app/components/EntityLink/PropertyLink.vue').default,SimpleTableCell: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableCell.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,ChromeTooltip: require('/app/components/Chrome/ChromeTooltip.vue').default,OwnerLink: require('/app/components/EntityLink/OwnerLink.vue').default,SimpleTableRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableRow.vue').default})
