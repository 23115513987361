
export default {
  name: "SearchResultTableCellProperty",
  inheritAttrs: false,

  props: {
    /**
     * BFE number
     */
    bfeNumber: {
      type: [String, Number],
      required: true,
    },

    /**
     * text of address field
     */
    addressText: {
      type: String,
      required: true,
    },

    /**
     * text of type field
     */
    typeText: {
      type: String,
      required: true,
    },

    isPropertyOnList: {
      type: Boolean,
      default: false,
    },

    /**
     * interface: [{key, value}]
     */
    keyFigures: {
      type: Object,
      default: () => {
        return [];
      },
    },

    /**
     * interface: [{key, value}]
     */
    keyFigureLabels: {
      type: Array,
      default: () => {
        return [];
      },
    },

    /**
     * interface: [{name:String, type:"company"|"person"}]
     */
    owners: {
      type: Array,
      default: () => {
        return [];
      },
    },

    /**
     * interface: [{name}]
     */
    administrators: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      characterLimit: 17,
    };
  },

  methods: {
    getKeyFigure(entity, key) {
      return entity[key] || this.$t("EXPLORE_UNKNOWN");
    },

    showKeyFigure(entity, key) {
      return entity && entity[key] && entity[key].length > this.characterLimit;
    },
  },
};
